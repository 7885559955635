@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    h1 {
        @apply font-Public text-white text-left font-bold transicion
         text-[40px] leading-[45px]  sm:text-center
         sm:text-[24px] sm:leading-[34px]
         md:text-[32px] lg:text-[40px] lg:leading-[48px]
         xl:text-[56px] xl:leading-[72px] 
    }

    h2 {
        @apply font-bold font-Public text-[28px] leading-[39.68px]
        sm:text-[34px] sm:leading-[40px]
        md:text-[40px] md:leading-[55px]
        lg:text-[46px] lg:leading-[63px]
    }

    h4 {
        @apply font-[700] font-Public text-[20px] leading-[28px] text-left
        sm:text-[34px] sm:leading-[40px]
        md:text-[40px] md:leading-[55px]
        lg:text-[40px] lg:leading-[54px]
    }

    h5 {
        @apply font-[600] font-Public text-[12px] leading-[14px] 
        sm:text-[32px] sm:leading-[39.68px] sm:font-bold text-center
    }

    ul {
        @apply font-[500] text-[16px]  leading-[20px] font-Public
        sm:leading-[38px] sm:text-[22px] md:pl-[2.5%]
        md:leading-[48px] md:text-[32px] 
        xl:text-[36px] xl:leading-[46px]
    }

    ol {
        @apply font-[500] font-Public text-[18px] leading-[27.72px] text-[#181818]
        sm:text-[18px] sm:leading-[27.72px]
        md:text-[32px] md:leading-[48px]
    }

    p {
        @apply  font-Public font-[400] tracking-[-0.01px] leading-[19.52px] text-[16px]
                sm:leading-[23.52px] sm:text-[20px]
                lg:leading-[42px] lg:text-[28px]
    }

    cite {
        @apply  italic font-[300] tracking-[-0.01px] text-[14px] leading-[19px]
                sm:text-[17px] sm:leading-[23.68px] 
                md:text-[20px] md:leading-[28.68px] 
                xl:text-[24px] xl:leading-[31.68px] 
    }
}

@layer components {
    .imgBack {
        @apply bg-cover bg-no-repeat bg-top h-auto xl:h-[calc(100vh-85px)]
    }

    .transicion {
        @apply transition-all ease-in
    }

    .contenedor {
        @apply w-[313px] mx-auto sm:w-auto sm:mx-[9%] xl:w-[1040px] xl:mx-auto
    }

    .contSalas {
        @apply  w-[311px] mx-auto
                sm:px-[8%] sm:w-auto
                xl:px-0 xl:w-[1016px]
    }

    .separador{
        @apply w-full h-full
    }

    .contSalasAux {
        @apply  w-[311px] mx-auto grid justify-items-center
                gap-y-[10px]
                sm:px-[8%] sm:w-auto grid-cols-2
                lg:grid-cols-3 xl:grid-cols-4 lg:gap-y-[32px]
                desktop:grid-cols-5 desktop:px-0 desktop:w-[1440px]
    }
}

@layer utilities {
    .lineAux {
        @apply bg-white h-[2px] w-[50%] block ml-[2.5px] 
                md:h-[4px]
    }

    .pySection {
        @apply py-[56px] md:py-[88px] lg:py-[104px]
    }

    .mtPharagraphs {
        @apply mt-[16px] sm:mt-[24px] md:mt-[32px] xl:mt-[44px]
    }

    .overflow {
        overflow-x: auto;
    }

    .textSpam {
        @apply  font-Public font-[600] text-[16px] leading-[22px]
                md:text-[40px] md:leading-[55px]
    }

    .liNav {
        @apply  grid grid-cols-[96px_25px] text-[32px] gap-[15px] items-center
                md:grid-cols-[194px_55px]  md:gap-[24px] md:text-[64px]
    }

    .liNumNavActive {
        @apply text-[24px] leading-[33px] md:text-[32px] md:leading-[44px] text-black
                
    }

    .liNumNavDisabled {
        @apply text-[24px] leading-[33px] md:text-[32px] md:leading-[44px] text-[#828282]
    }

    .spamNumNavActive {
        @apply h-[2px] w-[20px] bg-black block
    }

    .spamNumNavDisabled {
        @apply h-[2px] w-[20px] bg-[#828282] block
    }

    .dropDow :hover{
        background: red;
    }

    .salas::-webkit-scrollbar {
        -webkit-appearance: none;
        background-color: rgb(184, 184, 184);
        border-radius: 10px;  
    }
    
    .salas::-webkit-scrollbar:vertical {
        width:13px;
    }
    
    .salas::-webkit-scrollbar-button:increment,.salas::-webkit-scrollbar-button {
        display: none;
    } 
    
    .salas::-webkit-scrollbar-thumb {
        background-color: #103A62;
        border-radius: 20px;
        border: 2px solid #103A62;
    }
    
    .salas::-webkit-scrollbar-track {
        border-radius: 10px;  
    }
}